import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';
import { PeopleCard } from "../../../components";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const RemovePeople = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchedPeople, setSearchedPeople] = useState([]);
  const [selectedPeopleId, setSelectedPeopleId] = useState("");


  const handlerSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value)
  }


  const searchPeople = async () => {
    try {
      if (debouncedSearch) {
        const res = await Axios.post("/searchUsersByRole", {
          search: debouncedSearch,
          role: ["artist", "god", "user", "admin"]
        })
        setSearchedPeople(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    searchPeople();
    if (debouncedSearch === "") {
      setSearchedPeople([])
      setSelectedPeopleId("")
    }
  }, [debouncedSearch])


  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);


  const handleFormSubmit = async () => {
    
    try {
      console.log("id: ", selectedPeopleId)

      const response = await Axios.delete(`/deleteUser/${selectedPeopleId}`);
      console.log(response.data);

      toast.success(`People ${selectedPeopleId} removed successfully!`, { duration: 3000 });
      setSelectedPeopleId("");
      setSearchedPeople([]);
      setDebouncedSearch("");
      setSearch("")

    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  return (
    <Box m="20px">
      <Header title="Remove People" subtitle="Remove people from database" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Enter Artist / Writer / Composer / Singer name to search..."
          onChange={(e) => {
            handlerSearch(e)
          }}
          name="search"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Find People
        </Button>
      </Box>

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Selected People Id"
          value={selectedPeopleId}
          name="search"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained"
          onClick={handleFormSubmit}
        >
          Remove People
        </Button>
      </Box>

      {
        searchedPeople?.map((people) => (
          <div className="people-card-container" onClick={() => setSelectedPeopleId(people._id)}>
            <b>{people?.display_name}</b>
            <p>Username: {people.username}</p>
          </div>
        ))
      }




    </Box>
  );
};


export default RemovePeople;
