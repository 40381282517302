import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TagUs = [
  "Random 1",
  "Random 2",
  "Random 3",
  "Random 4",
  "Random 5"
]


const SERVER_URL = process.env.REACT_APP_SERVER_URL

const TagsInput = ({ tags, setTagsCallback, selectedTags }) => {
  const removeTags = (indexToRemove) => {
    setTagsCallback((prevTags) => [...prevTags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      const newTag = event.target.value.trim();

      if (!tags.includes(newTag)) {
        setTagsCallback((prevTags) => [...prevTags, newTag]);
        selectedTags([...tags, newTag]);
        setTagsCallback((prevTags) => [...prevTags, newTag]);
      }

      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {
          Array.from(new Set(tags)).map((tag, index) => (
            <li key={index} className="tag">
              <span className='tag-title'>{tag}</span>
              <span
                className='tag-close-icon'
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder="Press enter to add tags"
      />
    </div>
  );
};

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [tags, setTags] = React.useState([]);
  const [literatureTag, setLiteratureTag] = React.useState([]);
  const [godsTag, setGodsTag] = React.useState([]);
  const [languageTag, setLanguageTag] = React.useState([]);
  const [writerName, setWriterName] = React.useState("");
  const [composerName, setComposerName] = React.useState("");
  const [singerName, setSingerName] = React.useState("");
  const [musicFilename, setMusicFilename] = React.useState("");
  const [lyricsFilename, setLyricsFilename] = React.useState("");
  const [thumbnailFile, setThumbnailFile] = React.useState("");

  const [thumbnailUrl, setThumbnailUrl] = React.useState("");

  const [musicUrl, setMusicUrl] = React.useState("");
  const [lyricsUrl, setLyricsUrl] = React.useState("");

  const [languageOpt, setLanguageOpt] = useState([])
  const [languagePref, setlanguagePref] = useState("")

  const [literatureOpt, setLiteratureOpt] = useState([])
  const [peopleOpt, setPeopleOpt] = useState([])

  const selectedTags = tags => {
    console.log(tags);
  };

  const GetLanguages = async () => {
    const res = await Axios.get('/v2/getLanguageTags');
    setLanguageOpt(res.data.languages)
  }


  useEffect(() => {
    // GetGodTags()
    GetLanguages()
  }, [])


  // ONCHANGE HANDLERS
  const handleLiteratureChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set the literatureTag state
    setLiteratureTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
  };

  const handleGodsChange = (event) => {
    const {
      target: { value },
    } = event;
    setGodsTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
  };

  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
    //
  };

  useEffect(() => {
    const getLiterature = async () => {
      if (languagePref) {
        let res = await Axios.post("/v2/getLiteraturesByLanguage", {
          language: languagePref
        })
        setLiteratureOpt(res.data.literatures)

        let peopleRes = await Axios.post('/v2/getPeopleByLanguage', {
          language: languagePref
        })
        setPeopleOpt(peopleRes.data.peoples)
      }
    }

    getLiterature()
  }, [languagePref])

  // FILE UPLOADING HANDLERS
  const handleMusicFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('musicFile', file);

        const response = await fetch(`${SERVER_URL}/uploadMusic`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }
        const { filename, url } = await response.json();
        setMusicFilename(filename);
        setMusicUrl(url);
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleLRCFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('lyricsFile', file);

        const response = await fetch(`${SERVER_URL}/uploadLyrics`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }

        const { filename, url } = await response.json();
        setLyricsFilename(filename);
        setLyricsUrl(url);
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('thumbnail', file);

        const response = await fetch(`${SERVER_URL}/uploadMusicThumbnail`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }

        const { filename, url } = await response.json();
        setThumbnailFile(filename);
        setThumbnailUrl(url)
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleRemoveFile = async (fileType, filename) => {
    try {
      let payload = {
        filename: filename,
        path: fileType,
      }
      const response = await Axios.delete('/removeFile', { data: payload })
      toast.success(response.data.message || 'Something went Wrong', { duration: 3000 });

      // eslint-disable-next-line 
      switch (fileType) {
        case "thumbnails":
          setThumbnailFile("")
          break;
        case "lyrics":
          setLyricsFilename("");
          break;
        case "tracks":
          setMusicFilename("");
          break;
      }

    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  function uniqueArray(inputArray) {
    return Array.from(new Set(inputArray));
  }

  // HANDLE SUBMIT
  const handleFormSubmit = async (values) => {

    let TrackData = {
      music_title: values.musicTitle,
      gods: values.godsTag,
      language: values.languageTag,
      literature_type: values.literatureTag,
      writer: values.writerName,
      singer: values.singerName,
      composer: values.composerName,
      music_thumbnail: thumbnailUrl,
      audio_metadata: {
        filename: musicFilename,
        url: musicUrl
      },
      duration: values.duration,
      lyrics_metadata: {
        filename: lyricsFilename,
        url: lyricsUrl
      },
      tags: uniqueArray(tags),
      description: values.description
    }

    try {
      const response = await Axios.post('/addMusic', TrackData);

      console.log(response.data);
      toast.success('Music added successfully!', { duration: 3000 });



    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  return (
    <Box m="20px">
      <Header title="Add Track" subtitle="Add a new track to Bucket" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          errors
        }) => (
          <Forma>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Music Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.musicTitle}
                name="musicTitle"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Writer Name"
                onBlur={(e) => {
                  handleBlur(e);
                  if (e.target.value !== "") {
                    setTags([...tags, writerName]);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setWriterName(e.target.value);
                }}
                value={values.writerName}
                name="writerName"
                error={!!touched.writerName && !!errors.writerName}
                helperText={touched.writerName && errors.writerName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Composer Name"
                onBlur={(e) => {
                  handleBlur(e);
                  if (e.target.value !== "") {
                    setTags([...tags, composerName]);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setComposerName(e.target.value);
                }}
                value={values.composerName}
                name="composerName"
                error={!!touched.composerName && !!errors.composerName}
                helperText={touched.composerName && errors.composerName}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Language Type */}
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="literature-checkbox-label">Language</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  variant="filled"
                  multiple
                  placeholder="Select Language"
                  sx={{ gridColumn: "span 2" }}
                  value={languageTag && (values.languageTag = languageTag)}
                  name="languageTag"
                  onChange={(e) => {
                    handleLanguageChange(e)
                    setlanguagePref(e.target.value[0])
                  }}
                  input={<OutlinedInput label="Language" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {languageOpt?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={languageTag.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Singer Name */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Singer Name"
                onBlur={(e) => {
                  handleBlur(e);
                  if (e.target.value !== "") {
                    setTags([...tags, singerName]);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setSingerName(e.target.value);
                }}
                value={values.singerName}
                name="singerName"
                error={!!touched.singerName && !!errors.singerName}
                helperText={touched.singerName && errors.singerName}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Literature Type */}
              <FormControl sx={{ gridColumn: "span 4" }}>
                <InputLabel id="literature-checkbox-label">Literature</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  variant="filled"
                  multiple
                  placeholder="Select Tags"
                  sx={{ gridColumn: "span 4" }}
                  value={literatureTag && (values.literatureTag = literatureTag)}
                  onChange={handleLiteratureChange}
                  input={<OutlinedInput label="Literature" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {literatureOpt?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={literatureTag.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Gods Type */}
              <FormControl sx={{ gridColumn: "span 4" }}>
                <InputLabel id="literature-checkbox-label">Gods</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  variant="filled"
                  multiple
                  placeholder="Select Tags"
                  sx={{ gridColumn: "span 4" }}
                  value={godsTag && (values.godsTag = godsTag)}
                  onChange={handleGodsChange}
                  input={<OutlinedInput label="Gods" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {peopleOpt?.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={godsTag.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Duration"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.duration}
                name="duration"
                error={!!touched.duration && !!errors.duration}
                helperText={touched.duration && errors.duration}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
              {/* Tags */}
              <FormControl sx={{ gridColumn: "span 4" }}>
                <FormLabel id="demo-multiple-checkbox-label">Tags</FormLabel>
                <div className="all-tags-selected-container">
                  <TagsInput
                    tags={tags}
                    setTagsCallback={setTags}
                    selectedTags={selectedTags}
                  />
                </div>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">

              {/* Upload Lyrics File button  */}

              {lyricsFilename ?
                <Button
                  style={{ backgroundColor: "#V15921", marginRight: ".5rem" }}
                  component="label"
                  variant="contained"
                  endIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => handleRemoveFile("lyrics", lyricsFilename)}
                >
                  {lyricsFilename} Remove it
                </Button> :
                <Button style={{ backgroundColor: "#E5672F", marginRight: ".5rem" }}
                  component="label"
                  variant="contained"
                  onChange={handleLRCFileUpload}
                  startIcon={<CloudUploadIcon />}>
                  Add LRC file
                  <VisuallyHiddenInput type="file" />
                </Button>

              }

              {/* Upload Thumbnail File button  */}

              {thumbnailFile ?
                <Button
                  style={{ backgroundColor: "#V15921", marginRight: ".5rem" }}
                  component="label"
                  variant="contained"
                  endIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => handleRemoveFile("thumbnails", thumbnailFile)}
                >
                  {thumbnailFile} Remove it
                </Button>
                : (
                  <Button style={{ backgroundColor: "#E5672F", marginRight: ".5rem" }}
                    component="label"
                    variant="contained"
                    onChange={handleThumbnailUpload}
                    startIcon={<CloudUploadIcon />}>
                    Add Thumbnail
                    <VisuallyHiddenInput type="file" />
                  </Button>
                )
              }

              {/* Upload Music File button  */}

              {
                musicFilename ?
                  <Button
                    style={{ backgroundColor: "#V15921", marginRight: ".5rem" }}
                    component="label"
                    variant="contained"
                    endIcon={<RemoveCircleOutlineIcon />}
                    onClick={() => handleRemoveFile("tracks", musicFilename)}
                  >
                    {musicFilename} Remove it
                  </Button> :
                  <Button style={{ backgroundColor: "#E5672F", marginRight: ".5rem" }}
                    component="label"
                    variant="contained"
                    onChange={handleMusicFileUpload}
                    startIcon={<CloudUploadIcon />}>
                    Upload Music file
                    <VisuallyHiddenInput type="file" />
                  </Button>
              }

              {/* Submit Track */}

              <Button type="submit" color="secondary" variant="contained">
                Add New Track
              </Button>
            </Box>
          </Forma>
        )}
      </Formik>
    </Box>
  );
};


const initialValues = {
  musicTitle: '',
  writerName: '',
  composerName: '',
  singerName: '',
  languageTag: [],
  literatureTag: [],
  godsTag: [],
  duration: '',
  tags: [],
}

export default Form;
