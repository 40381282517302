import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TagUs = [
  "Random 1",
  "Random 2",
  "Random 3",
  "Random 4",
  "Random 5"
]



const SERVER_URL = process.env.REACT_APP_SERVER_URL

const TagsInput = ({ tags, setTagsCallback, selectedTags }) => {
  const removeTags = (indexToRemove) => {
    setTagsCallback((prevTags) => [...prevTags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      const newTag = event.target.value.trim();

      if (!tags.includes(newTag)) {
        setTagsCallback((prevTags) => [...prevTags, newTag]);
        selectedTags([...tags, newTag]);
        setTagsCallback((prevTags) => [...prevTags, newTag]);
      }

      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {
          Array.from(new Set(tags)).map((tag, index) => (
            <li key={index} className="tag">
              <span className='tag-title'>{tag}</span>
              <span
                className='tag-close-icon'
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder="Press enter to add tags"
      />
    </div>
  );
};

const AddPlaylist = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [musicFilename, setMusicFilename] = React.useState("");
  const [lyricsFilename, setLyricsFilename] = React.useState("");
  const [thumbnailFile, setThumbnailFile] = React.useState("");

  const [thumbnailUrl, setThumbnailUrl] = React.useState("");
  const [view, setView] = React.useState("");

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleRemoveFile = async (fileType, filename) => {
    try {
      let payload = {
        filename: filename,
        path: fileType,
      }
      const response = await Axios.delete('/removeFile', { data: payload })
      toast.success(response.data.message || 'Something went Wrong', { duration: 3000 });

      // eslint-disable-next-line 
      switch (fileType) {
        case "thumbnails":
          setThumbnailFile("")
          break;
        case "lyrics":
          setLyricsFilename("");
          break;
        case "tracks":
          setMusicFilename("");
          break;
      }

    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  // HANDLE SUBMIT
  const handleFormSubmit = async (values) => {


    console.log(values)
    try {
      // const response = await Axios.post('/addMusic', TrackData);

      // console.log(response.data);
      // toast.success('Music added successfully!', { duration: 3000 });



    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  return (
    <Box m="20px">
      <Header title="Add Playlist" subtitle="Add a new playlist to database" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          errors
        }) => (
          <Forma>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Add Playlist"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.playlist_name}
                name="playlist_name"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="UserName"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Category"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.category}
                name="category"
                error={!!touched.category && !!errors.category}
                helperText={touched.category && errors.category}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel id="demo-simple-select-label">View</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.view || view}
                  label="view"
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => {
                    handleChange(e);
                    handleViewChange(e)
                  }}
                >
                  <MenuItem value={"public"}>Public</MenuItem>
                  <MenuItem value={"private"}>Private</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">

              {/* Upload user image */}




              {/* Submit Track */}

              <Button type="submit" color="secondary" variant="contained">
                Add Playlist
              </Button>
            </Box>
          </Forma>
        )}
      </Formik>
    </Box>
  );
};


const initialValues = {
  playlist_name: '',
  username: '',
  view: '',
  category: '',
}

export default AddPlaylist;
