import { Box, Button, TextField } from "@mui/material";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { instance as Axios } from '../../config/axios';
import './index.css';
const RemoveTrack = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [musicTile, setMusicTitle] = React.useState("");
  const [musicData, setMusicData] = React.useState([]);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchedTrack, setSearchedTrack] = useState([]);
  const [selectedTrackId, setSelectedTrackId] = useState("");


  const handlerSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value)
  }


  const searchTrack = async () => {
    try {
      if (debouncedSearch) {
        const res = await Axios.post("/searchMusic", {
          search: debouncedSearch,
        })
        console.log(res.data)
        setSearchedTrack(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    searchTrack();
    if (debouncedSearch === "") {
      setSearchedTrack([]);
      setSelectedTrackId("");
    }
  }, [debouncedSearch])


  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);




  // HANDLE SUBMIT
  const handleDelete = async (values) => {

    try {
      const res = await Axios.delete(`/deleteMusic/${selectedTrackId}`);
      toast.success(` ${res.data.message}`, { duration: 3000 });
      setSelectedTrackId("");
    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };



  return (
    <Box m="20px">
      <Header title="Remove Track" subtitle="Remove track" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Enter track / Artist / Writer / Composer / Singer to search..."
          onChange={(e) => {
            handlerSearch(e)
          }}
          name="musicTitle"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          marginTop: '1rem'
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Select Track Id"
          name="trackid"
          value={selectedTrackId}
          sx={{ gridColumn: "span 4" }}
        />
      </Box>

      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained"
          onClick={handleDelete}
        >
          Delete Track
        </Button>
      </Box>

      {
        searchedTrack?.map((track) => (
          <div className="track-container" 
            id={track._id}
            onClick={() => setSelectedTrackId(track._id)}
          >
            <b>{track.music_title}</b>
            <p>Writer: <b>{track.writer}</b> • Composer: <b>{track.composer}</b> • Singer: <b>{track.singer}</b></p>
            <p>Tags: {track.tags.toString()}</p>
          </div>
        ))
      }

      
    </Box>
  );
};


const initialValues = {
  musicTitle: "",
  writerName: "",
  composerName: "",
  singerName: "",
};

export default RemoveTrack;
