import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const SelectedPerson = (people) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    setFormData(people)
  }, [people])
  // State to manage the form inputs
  const [formData, setFormData] = useState({});

  // Handle change for mutable fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can submit formData to update the person's details
    const payload = {
      username: formData.username,
      sneakusername: formData.sneakusername,
      signature: formData?.signature || "",
      settings: {
        role: formData.settings.role,
        bio: formData.bio,
      }
    }
    console.log('Form submitted with data:', payload);
    try {
      const res = await Axios.patch(`/updateUser/${formData.username}`, payload)

      toast.success(`People ${res.data._id} updated successfully!`, { duration: 3000 });
    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Username"
          value={formData.display_name}
          name="display_name"
          onChange={handleChange}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Username"
          value={formData.username}
          name="username"
          onChange={handleChange}
          sx={{ gridColumn: "span 2" }}

        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Sneak username"
          value={formData.sneakusername}
          name="sneakusername"
          onChange={handleChange}
          sx={{ gridColumn: "span 2" }}
          disabled // Disable this field to make it immutable
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Signature"
          value={formData?.signature}
          name="signature"
          onChange={handleChange}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Role"
          value={formData.settings?.role}
          name="role"
          onChange={handleChange}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Bio"
          value={formData.settings?.bio}
          name="bio"
          onChange={handleChange}
          sx={{ gridColumn: "span 4" }}
        />
      </Box>

      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Update
        </Button>
      </Box>
    </form>
  );
};


const UpdatePeople = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchedPeople, setSearchedPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState({});



  const handlerSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value)
  }


  const searchPeople = async () => {
    try {
      if (debouncedSearch) {
        const res = await Axios.post("/searchUsersByRole", {
          search: debouncedSearch,
          role: ["artist", "god", "user", "admin"]
        })
        setSearchedPeople(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    searchPeople();
    if (debouncedSearch === "") {
      setSearchedPeople([])
      setSelectedPeople({})
    }
  }, [debouncedSearch])


  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);


  const handleFormSubmit = async () => {

    try {
      console.log("id: ", selectedPeople)
      setSelectedPeople("");
      setSearchedPeople([]);
      setDebouncedSearch("");
      setSearch("")

    } catch (err) {

    }
  };

  useEffect(() => {
    // This useEffect hook will be triggered every time selectedPeople._id changes
    // You can put any logic here that needs to run when selectedPeople._id changes
    // For now, we'll log the selectedPeople object
    console.log(selectedPeople);
  }, [selectedPeople._id]);

  return (
    <Box m="20px">
      <Header title="Remove People" subtitle="Remove people from database" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Enter Artist / Writer / Composer / Singer name to search..."
          onChange={(e) => {
            handlerSearch(e)
          }}
          name="search"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Find People
        </Button>
      </Box>



      {/* Render selected person */}
      {selectedPeople._id && <SelectedPerson {...selectedPeople} />}

      {/* Render searched people */}
      {searchedPeople.map((person) => (
        <div
          className="people-card-container"
          key={person._id} // Assuming person has a unique identifier
          onClick={() => {
            console.log(person)
            setSelectedPeople(person)
          }} // Set the selected person on click
          style={{ cursor: "pointer" }}
        >
          <b>{person.display_name}</b>
          <p>Username: {person.username}</p>
        </div>
      ))}



    </Box >
  );
};


export default UpdatePeople;
