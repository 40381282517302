import React, { createContext, useContext, useState, useEffect } from 'react';
import { instance as Axios } from '../config/axios';
// Create the AuthContext
const AuthContext = createContext();

// AuthProvider component to wrap the entire application
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);


  const loginUser = async (email, password) => {
    try {
      const response = await Axios.post('/loginUser', {
        email: email,
        password: password
      })

      const access_token = response.data.access_token
      setUser(response.data.email)

      Axios.defaults.headers.common['Authorization'] = access_token
    }catch(err) {

    }
    
  };


  const logoutUser = () => {

  };


  useEffect(() => {

    setLoading(false);
  }, []);

  // Provide the context values to the components
  const contextValues = {
    user,
    loading,
    loginUser,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};