import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const SERVER_URL = process.env.REACT_APP_SERVER_URL

const TagsInput = ({ tags, setTagsCallback, selectedTags }) => {
  const removeTags = (indexToRemove) => {
    setTagsCallback((prevTags) => [...prevTags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      const newTag = event.target.value.trim();

      if (!tags.includes(newTag)) {
        setTagsCallback((prevTags) => [...prevTags, newTag]);
        selectedTags([...tags, newTag]);
        setTagsCallback((prevTags) => [...prevTags, newTag]);
      }

      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {
          Array.from(new Set(tags)).map((tag, index) => (
            <li key={index} className="tag">
              <span className='tag-title'>{tag}</span>
              <span
                className='tag-close-icon'
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder="Press enter to add tags"
      />
    </div>
  );
};

const AddPeople = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [tags, setTags] = React.useState([]);
  const [literatureTag, setLiteratureTag] = React.useState([]);
  const [godsTag, setGodsTag] = React.useState([]);
  const [languageTag, setLanguageTag] = React.useState([]);
  const [writerName, setWriterName] = React.useState("");
  const [composerName, setComposerName] = React.useState("");
  const [singerName, setSingerName] = React.useState("");
  const [musicFilename, setMusicFilename] = React.useState("");
  const [lyricsFilename, setLyricsFilename] = React.useState("");
  const [thumbnailFile, setThumbnailFile] = React.useState("");

  const [thumbnailUrl, setThumbnailUrl] = React.useState("");

  const [musicUrl, setMusicUrl] = React.useState("");
  const [lyricsUrl, setLyricsUrl] = React.useState("");

  const [languageOpt, setLanguageOpt] = useState([])
  const [languagePref, setlanguagePref] = useState("")

  const [literatureOpt, setLiteratureOpt] = useState([])
  const [peopleOpt, setPeopleOpt] = useState([])

  const selectedTags = tags => {
    console.log(tags);
  };

  const GetLanguages = async () => {
    const res = await Axios.get('/v2/getLanguageTags');
    setLanguageOpt(res.data.languages)
  }


  useEffect(() => {
    // GetGodTags()
    GetLanguages()
  }, [])


  // ONCHANGE HANDLERS
  const handleLiteratureChange = (event) => {
    const {
      target: { value },
    } = event;

    // Set the literatureTag state
    setLiteratureTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
  };

  const handleGodsChange = (event) => {
    const {
      target: { value },
    } = event;
    setGodsTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
  };

  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageTag(
      typeof value === 'string' ? value.split(',') : value,
    );
    const newTags = [...tags, ...value];
    setTags(newTags);
    //
  };

  useEffect(() => {
    const getLiterature = async () => {
      if (languagePref) {
        let res = await Axios.post("/v2/getLiteraturesByLanguage", {
          language: languagePref
        })
        setLiteratureOpt(res.data.literatures)

        let peopleRes = await Axios.post('/v2/getPeopleByLanguage', {
          language: languagePref
        })
        setPeopleOpt(peopleRes.data.peoples)
      }
    }

    getLiterature()
  }, [languagePref])

  // FILE UPLOADING HANDLERS
  const handleMusicFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('musicFile', file);

        const response = await fetch(`${SERVER_URL}/uploadMusic`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }
        const { filename, url } = await response.json();
        setMusicFilename(filename);
        setMusicUrl(url);
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleLRCFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('lyricsFile', file);

        const response = await fetch(`${SERVER_URL}/uploadLyrics`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }

        const { filename, url } = await response.json();
        setLyricsFilename(filename);
        setLyricsUrl(url);
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append('thumbnail', file);

        const response = await fetch(`${SERVER_URL}/uploadMusicThumbnail`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          console.error('Failed to upload music file');
          return;
        }

        const { filename, url } = await response.json();
        setThumbnailFile(filename);
        setThumbnailUrl(url)
        toast.success(`${filename} added successfully.`, { duration: 3000 });

        console.log('File uploaded successfully. FileName:', filename);
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    }
  };

  const handleRemoveFile = async (fileType, filename) => {
    try {
      let payload = {
        filename: filename,
        path: fileType,
      }
      const response = await Axios.delete('/removeFile', { data: payload })
      toast.success(response.data.message || 'Something went Wrong', { duration: 3000 });

      // eslint-disable-next-line 
      switch (fileType) {
        case "thumbnails":
          setThumbnailFile("")
          break;
        case "lyrics":
          setLyricsFilename("");
          break;
        case "tracks":
          setMusicFilename("");
          break;
      }

    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  function uniqueArray(inputArray) {
    return Array.from(new Set(inputArray));
  }

  // HANDLE SUBMIT
  const handleFormSubmit = async (values) => {
    let payload = {
      display_name: values.display_name,
      username: values.username,
      signature: values.signature,
      settings: {
        role: values.role,
        profile_img: {
          url: values.profile_img_url 
        },
      },
      bio: values.bio
    }

    console.log(payload)
    try {
      // const response = await Axios.post('/addMusic', TrackData);

      // console.log(response.data);
      // toast.success('Music added successfully!', { duration: 3000 });



    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  return (
    <Box m="20px">
      <Header title="Add People" subtitle="Add a new people to database" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          errors
        }) => (
          <Forma>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Display Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.display_name}
                name="display_name"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="UserName"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="People role"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.role}
                name="role"
                error={!!touched.role && !!errors.role}
                helperText={touched.role && errors.role}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Singer Name */}
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Signature"
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.signature}
                name="signature"
                error={!!touched.signature && !!errors.signature}
                helperText={touched.signature && errors.signature}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Profile image url"
                defaultValue={"https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.profile_img_url}
                name="profile_img_url"
                error={!!touched.profile_img_url && !!errors.profile_img_url}
                helperText={touched.profile_img_url && errors.profile_img_url}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Bio"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bio}
                name="bio"
                error={!!touched.bio && !!errors.bio}
                helperText={touched.bio && errors.bio}
                sx={{ gridColumn: "span 4" }}
              />

  
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">

              {/* Upload user image */}

              {thumbnailFile ?
                <Button
                  style={{ backgroundColor: "#V15921", marginRight: ".5rem" }}
                  component="label"
                  variant="contained"
                  endIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => handleRemoveFile("thumbnails", thumbnailFile)}
                >
                  {thumbnailFile} Remove it
                </Button>
                : (
                  <Button style={{ backgroundColor: "#E5672F", marginRight: ".5rem" }}
                    component="label"
                    variant="contained"
                    onChange={handleThumbnailUpload}
                    startIcon={<CloudUploadIcon />}>
                    Add Profile image
                    <VisuallyHiddenInput type="file" />
                  </Button>
                )
              }


              {/* Submit Track */}

              <Button type="submit" color="secondary" variant="contained">
                Add People
              </Button>
            </Box>
          </Forma>
        )}
      </Formik>
    </Box>
  );
};


const initialValues = {
  display_name: '',
  username: '',
  signature: '',  
  role: 'user',
  profile_img_url: 'https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg',
  bio: '',
}

export default AddPeople;
