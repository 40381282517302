import { Box, Button, FormLabel, TextField } from "@mui/material";
import { Formik, Form as Forma } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from "../../components/Header";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import { instance as Axios } from '../../config/axios';
import { toast } from 'react-toastify';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import './index.css';
import Card from "../../components/track/trackcard";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const TagsInput = ({ tags, setTagsCallback, selectedTags }) => {
  const removeTags = (indexToRemove) => {
    setTagsCallback((prevTags) => [...prevTags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      const newTag = event.target.value.trim();

      if (!tags.includes(newTag)) {
        setTagsCallback((prevTags) => [...prevTags, newTag]);
        selectedTags([...tags, newTag]);
        setTagsCallback((prevTags) => [...prevTags, newTag]);
      }

      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {
          Array.from(new Set(tags)).map((tag, index) => (
            <li key={index} className="tag">
              <span className='tag-title'>{tag}</span>
              <span
                className='tag-close-icon'
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        placeholder="Press enter to add tags"
      />
    </div>
  );
};

const UpdateTrack = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [musicTile, setMusicTitle] = React.useState("");


  const [musicData, setMusicData] = React.useState([]);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchedTrack, setSearchedTrack] = useState([]);
  const [selectedTrackId, setSelectedTrackId] = useState("");


  const handlerSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value)
  }


  const searchTrack = async () => {
    try {
      if (debouncedSearch) {
        const res = await Axios.post("/searchMusic", {
          search: debouncedSearch,
        })
        console.log(res.data)
        setSearchedTrack(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    searchTrack();
    if (debouncedSearch === "") {
      setSearchedTrack([]);
    }
  }, [debouncedSearch])


  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);




  // HANDLE SUBMIT
  const handleFormSubmit = async (values) => {

    try {
      // const response = await Axios.post('/updateMusic/:id', TrackData);

      // console.log(response.data);
      toast.success('Music added successfully!', { duration: 3000 });



    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  };

  const handleFind = async () => {
    try {
      const res = await Axios.post('/searchMusic', {
        search: ""
      })

      setMusicData(res.data.data)
      toast.success(`${res.data.data.length} data found`, { duration: 3000 });



    } catch (err) {
      toast.error(`Error: ${err.response?.data.error[0] || 'Something went wrong'}`, { duration: 3000 });
      console.error(err)
    }
  }

  return (
    <Box m="20px">
      <Header title="Update Track" subtitle="Update track" />

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Enter track / Artist / Writer / Composer / Singer to search..."
          onChange={(e) => {
            handlerSearch(e)
          }}
          name="musicTitle"
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Find Track
        </Button>
      </Box>

    </Box>
  );
};


const initialValues = {
  musicTitle: '',
  writerName: '',
  composerName: '',
  singerName: '',
  languageTag: [],
  literatureTag: [],
  godsTag: [],
  duration: '',
  tags: [],
}

export default UpdateTrack;
