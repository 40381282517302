import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Tracks from "./scenes/tracks";
import RemoveTrack from "./scenes/removetrack";
import Form from "./scenes/form";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import UpdateTrack from "./scenes/updatetrack";
import AddPeople from "./scenes/people/add";
import RemovePeople from "./scenes/people/remove";
import UpdatePeople from "./scenes/people/update";
import AddPlaylist from "./scenes/playlist/add";
import UpdatePlaylist from "./scenes/playlist/update";
import RemovePlaylist from "./scenes/playlist/remove";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <>

      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <Topbar setIsSidebar={setIsSidebar} />
              <Routes>

                <Route path="/" element={<Dashboard />} />
                <Route path="/Tracks" element={<Tracks />} />
                <Route path="/form" element={<Form />} />
                <Route path="/remove-track" element={<RemoveTrack />} />
                <Route path="/update-track" element={<UpdateTrack />} />
                <Route path="/add-people" element={<AddPeople />} />
                <Route path="/remove-people" element={<RemovePeople />} />
                <Route path="/update-people" element={<UpdatePeople />} />
                <Route path="/add-playlist" element={<AddPlaylist />} />
                <Route path="/update-playlist" element={<UpdatePlaylist />} />
                <Route path="/remove-playlist" element={<RemovePlaylist />} />
              </Routes>
            <ToastContainer />
            </main>
            
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
